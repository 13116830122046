//general
import React from "react"
//components
import AboutUsItem from "../../components/AboutUsItem"
//styles
import * as styles from "./styles.module.scss"

const AboutUs = ({ data: query }) => {
  const data = query.frontmatter

  const html = query.html
  const items = data.items

  return (
    <section id={"about"} className={styles.aboutUs}>
      <div className={styles.wrapper}>
        <div className={styles.introduction}>
          <h2 className={styles.title}>{data.pageTitle}</h2>

          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        </div>

        <div className={styles.items}>
          {items?.map(item => (
            <AboutUsItem key={item.title} icon={item.icon} text={item.text} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default AboutUs
