//general
import React, { useState } from "react"
import cn from "classnames"
import { getDonationApi } from "../../utils/api"
//ui
import Button from "../../ui/Button"
//constants
import { CURRENCIES } from "../../constants/currencies"
import { REFERRERS } from "../../constants/referrers"
//styles
import * as styles from "./styles.module.scss"
//assets
import QuickIcon from "../../assets/icons/quick.svg"
import MonoIcon from "../../assets/icons/monobank.svg"

const Hero = ({ data: query }) => {
  const [loading, setLoading] = useState(false)
  const data = query.frontmatter
  const [currency, setCurrency] = React.useState(CURRENCIES.USD)


  React.useEffect(() => {
    if (Object.values(REFERRERS).includes(document.referrer)) {
      setCurrency(CURRENCIES.UAH)
    }
  }, [])

  const handleDonate = () => {
    setLoading(true)
    getDonationApi(0, currency).then(response => {
      setLoading(false)

      if (response.status) {
        window.location.href = response.url
      }
    })
  }
  const handleMonobankDonate = () => {
    setLoading(true);
    window.location.href = "https://send.monobank.ua/jar/6SS1VsEG26";
  }
 
  return (
    <section className={styles.hero}>
      <div className={styles.textContainer}>
        <h3 className={cn(styles.text, { [styles.secondary]: true })}>
          {data.pageTitle}
        </h3>
        <h3 className={styles.text}>{data.description}</h3>
      </div>

      <div className={styles.donationContainer}>

        <div className={styles.donationOptions}> 
          <Button
            name={data?.monobankDonation}
            icon={<MonoIcon />}
            onClick={handleMonobankDonate}
            disabled={loading}
            theme = {"filledYellow"}
          />
          <Button
            name={data?.donation}
            icon={<QuickIcon />}
            onClick={handleDonate}
            disabled={loading}
            theme = {"filledBlue"}
          />
          <Button
            name={"SWIFT"}
            theme={"outline"}
            type="anchorLink"
            to={data?.swiftAnchor}
          />
        </div>
      </div>

      <div className={styles.imgContainer}>
        <img src={data.image} alt="This is hero" />
      </div>
    </section>
  )
}

export default Hero
