//general
import React from "react"
import cn from "classnames"
//styles
import * as styles from "./styles.module.scss"
//assets
import CopyIcon from "../../assets/icons/copy.svg"

const DonationDetail = ({ data }) => {
  const {
    title,
    name,
    iban,
    bank,
    swift,
    purpose,
    benef,
    paymentpurp,
    swiftTitle,
    titleicon,
    ibantitle,
    edrpuTitle,
    bankBef,
    bankName,
    copy,
    copied,
  } = data
  const [isCopiedShow, setIsCopiedShow] = React.useState(false)

  const onDonationClick = value => {
    navigator.clipboard.writeText(value)
    showAndHideCopiedMessage()
  }

  const clipboardAllInfo = () => {
    const code =
      title?.toLowerCase() === "uah"
        ? `EDRPOU Code: ${swift} \n`
        : `Bank SWIFT Code: ${swift} \n`

    const data = [
      `Name of beneficiary: ${name} \n`,
      `IBAN Code: ${iban} \n`,
      `Bank of beneficiary: ${bankName} \n`,
      code,
      `Purpose of payment: ${purpose} \n`,
    ]
    navigator.clipboard.writeText(data.join(""))
    showAndHideCopiedMessage()
  }

  const showAndHideCopiedMessage = () => {
    setIsCopiedShow(true)

    setTimeout(() => {
      setIsCopiedShow(false)
    }, 1500)
  }

  return (
    <div className={styles.detail} onClick={clipboardAllInfo}>
      <div className={styles.titleContainer}>
        <h4 className={styles.title}>{title}</h4>
        <img src={titleicon} alt={title} />
      </div>

      <div className={styles.data}>
        <div
          className={styles.item}
          onDoubleClick={() => onDonationClick(name)}
        >
          <label className={styles.label}>{benef}:</label>
          <span className={styles.value}>{name}</span>
        </div>

        <div
          className={styles.item}
          onDoubleClick={() => onDonationClick(iban)}
        >
          <label className={styles.label}>{ibantitle}:</label>
          <span className={styles.value}>{iban}</span>
        </div>

        <div
          className={styles.item}
          onDoubleClick={() => onDonationClick(bank)}
        >
          <label className={styles.label}>{bankBef}:</label>
          <span className={styles.value}>{bankName}</span>
        </div>

        <div
          className={styles.item}
          onDoubleClick={() => onDonationClick(swift)}
        >
          <label className={styles.label}>
            {title?.toLowerCase() === "uah" ? edrpuTitle : swiftTitle}:
          </label>
          <span className={styles.value}>{swift}</span>
        </div>

        <div
          className={styles.item}
          onDoubleClick={() => onDonationClick(purpose)}
        >
          <label className={styles.label}>{paymentpurp}:</label>
          <span className={styles.value}>{purpose}</span>
        </div>
      </div>

      <div className={styles.control}>
        <div className={cn(styles.copy, { [styles.hide]: isCopiedShow })}>
          {copy}

          <CopyIcon />
        </div>

        <div className={cn(styles.tooltip, { [styles.show]: isCopiedShow })}>
          {copied}
        </div>
      </div>
    </div>
  )
}

export default React.memo(DonationDetail)
