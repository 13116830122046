//general
import * as React from "react"
import { navigate } from "gatsby-link"
//translations
import withI18next from "../i18n/withi18n"
import { Translation } from "react-i18next"
import { graphql } from "gatsby"
//ui
import PopUp from "../components/PopUp"
//components
import Layout from "../components/Layout"
import Seo from "../components/Seo"
//templates
import Hero from "../templates/Hero"
import AboutUs from "../templates/AboutUs"
import OurResults from "../templates/OurResults"
import DonationDetails from "../templates/DonationDetails"
import useSplitDataByComponents from "../hooks/useSplitDataByComponents"

const IndexPage = ({ data }) => {
  const [isShowPopup, setIsShowPopup] = React.useState(false)
  let url = null

  const {
    aboutUsData,
    resultsData,
    ourRecentAimIsData,
    donationData,
    headerData,
    footerData,
    cookieAcceptData,
  } = useSplitDataByComponents(data)

  if (typeof window !== "undefined") {
    url = new URL(window.location.href)
  }

  React.useEffect(() => {
    if (localStorage.getItem("showPopup") === null) {
      localStorage.setItem("showPopup", JSON.stringify(false))
    }

    if (url.hash === "#success") {
      localStorage.setItem("showPopup", JSON.stringify(true))
      setIsShowPopup(true)
    }
  }, [])

  const closePopup = () => {
    if (url.hash === "#success") {
      navigate("/")
    }
    localStorage.setItem("showPopup", JSON.stringify(false))
    setIsShowPopup(false)
  }

  return (
    <Translation>
      {t => (
        <Layout
          headerData={headerData}
          footerData={footerData}
          cookieAcceptData={cookieAcceptData}
          t={t}
        >
          <Seo title="Home" />
          <Hero data={ourRecentAimIsData} />
          <AboutUs data={aboutUsData} />
          <OurResults data={resultsData} recentAimData={ourRecentAimIsData} />
          <DonationDetails data={donationData} />
          <PopUp isShow={isShowPopup} closeAction={closePopup} />
        </Layout>
      )}
    </Translation>
  )
}

export default withI18next()(IndexPage)

export const query = graphql`
  query ($lng: String!) {
    allMarkdownRemark(filter: { fields: { relativeDirectory: { eq: $lng } } }) {
      nodes {
        fields {
          slug
        }
        html
        frontmatter {
          image
          title
          description
          pageTitle
          link
          name
          copyright
          items {
            title
            text
            name
            description
            image
            icon
            purpose
            swift
            copyicon
            titleicon
            edrpu
            amount
            swiftTitle
            paymentpurp
            ibantitle
            iban
            edrpuTitle
            benef
            bankName
            bankBef
          }
          pagetitle
          text
          links {
            path
            title
            text
          }
          contactLinks {
            name
            path
            title
            type
          }
          copyrightlinks {
            name
            path
            title
          }
          donation
          monobankDonation
          reportText
          reportLink
          slogan
          sloganIcon
          customButton
          swiftAnchor
          copy
          copied
          languageicon
          buttonText
          titleText
          contacts {
            text
            title
          }
        }
      }
    }
  }
`
