//general
import React, { useState } from "react"

//ui
import Button from "../../ui/Button"
//styles
import * as styles from "./styles.module.scss"
//assets 
import MonoIcon from "../../assets/icons/monobank.svg"

function OurRecentAim({ image, name, pageTitle, donation, swiftAnchor }) {
  const [loading, setLoading] = useState(false)


  const handleMonobankDonate = () => {
    setLoading(true);
    window.location.href = "https://send.monobank.ua/jar/6SS1VsEG26";
  }
 

  return (
    <div className={styles.recentAim}>
      <h4 className={styles.title}>{pageTitle}</h4>

      <img className={styles.image} src={image} alt={`This is ${name}`} />

      <h4 className={styles.name}>{name}</h4>

      <div className={styles.controls}>
        <Button
          name={donation}
          icon={<MonoIcon />}
          onClick={handleMonobankDonate}
          disabled={loading}
          theme = {"filledYellow"}
        />
        <Button
          name={"SWIFT"}
          theme={"outline"}
          type="anchorLink"
          to={swiftAnchor}
        />
      </div>
    </div>
  )
}

export default OurRecentAim
