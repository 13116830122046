// extracted by mini-css-extract-plugin
export var control = "styles-module--control--da964";
export var copy = "styles-module--copy--bffc0";
export var data = "styles-module--data--600df";
export var detail = "styles-module--detail--bf74c";
export var hide = "styles-module--hide--481fb";
export var item = "styles-module--item--1c041";
export var label = "styles-module--label--242c1";
export var show = "styles-module--show--a28c4";
export var title = "styles-module--title--da410";
export var titleContainer = "styles-module--titleContainer--2d30c";
export var tooltip = "styles-module--tooltip--3b4c0";
export var value = "styles-module--value--f0af6";