//general
import React, { useState } from "react"
//api
import { getDonationApi } from "../../utils/api"
//constants
import { REFERRERS } from "../../constants/referrers"
import { CURRENCIES } from "../../constants/currencies"
//components
import DonationDetail from "../../components/DonationDetail"
//ui
import Button from "../../ui/Button"
//styles
import * as styles from "./styles.module.scss"
//assets
import QuickIcon from "../../assets/icons/quick.svg"

const DonationDetails = ({ data: query }) => {
  const data = query?.frontmatter
  const items = data?.items
  const { copy, copied } = data
  const [loading, setLoading] = useState(false)
  const [currency, setCurrency] = React.useState(CURRENCIES.USD)

  React.useEffect(() => {
    if (Object.values(REFERRERS).includes(document.referrer)) {
      setCurrency(CURRENCIES.UAH)
    }
  }, [])

  const handleDonate = () => {
    setLoading(true)
    getDonationApi(0, currency).then(response => {
      setLoading(false)

      if (response.status) {
        window.location.href = response.url
      }
    })
  }

  return (
    <section id={"donation"} className={styles.donationDetails}>
      <div className={styles.wrapper}>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>{data.pageTitle}</h2>

          <p className={styles.slogan}>
            <img src={data.sloganIcon} alt="This is slogan" />

            {data.slogan}
          </p>
        </div>

        <div className={styles.donations}>
          {items.map(item => (
            <DonationDetail
              key={item?.title}
              data={{
                ...item,
                copy,
                copied,
              }}
            />
          ))}
        </div>

        <div className={styles.quickDonation}>
          <Button
            name={data?.donation}
            icon={<QuickIcon />}
            onClick={handleDonate}
            disabled={loading}
          />
        </div>
      </div>
    </section>
  )
}

export default DonationDetails
