//general
import React from "react"
import cn from "classnames"
import { withTranslation } from "react-i18next"
//ui
import Button from "../../ui/Button"
//styles
import * as styles from "./styles.module.scss"
//assets
import CharitryIcon from "../../assets/icons/alsoJoin.svg"

const PopUp = ({ isShow = true, closeAction, t }) => {
  return (
    <div className={cn(styles.wrapper, { [styles.show]: isShow })}>
      <div className={styles.popupWrapper}>
        <div className={styles.popup}>
          <div className={styles.content}>
            <CharitryIcon />

            <p className={styles.text}>{t("thanksForDonation")}</p>
          </div>

          <Button name={t("closeThanksPopup")} onClick={closeAction} />
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(PopUp)
