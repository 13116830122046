//general
import React from "react"
//components
import OurResultItem from "../../components/OurResultItem"
import OurRecentAim from "../../components/OurRecentAim"
//ui
import Button from "../../ui/Button"
//styles
import * as styles from "./styles.module.scss"

const OurResults = ({ data: query, recentAimData }) => {
  const results = query.frontmatter 
  const items = results.items
  const recent = recentAimData.frontmatter

  const handleReportClick = () => {
    window.location.href = results?.reportLink;
  }
  return (
    <section id={"results"} className={styles.ourResults}>
      <h2 className={styles.title}>{results.pageTitle}</h2>
      <div className={styles.results}>
        {items.map(item => (
          <OurResultItem
            key={item?.title}
            image={item?.image}
            amount={item?.amount}
            name={item?.name}
            description={item?.description}
          />
        ))}
        <OurRecentAim
          image={recent?.image}
          name={recent?.description}
          pageTitle={recent?.pageTitle}
          donation={recent?.monobankDonation}
          swiftAnchor={recent?.swiftAnchor}
        />
      </div>
      <div className={styles.btnContainer}>
        <Button
              name={results?.reportText}  
              theme = {"filled"}
              onClick={handleReportClick}
            />
      </div>
    </section>
  )
}

export default OurResults
