//general
import React from "react"
//styles
import * as styles from "./styles.module.scss"

const OurResultItem = ({ image, amount, name, description }) => {
  return (
    <div className={styles.item}>
      <div className={styles.imgContainer}>
        <img src={image} alt={`This is ${name}`} />
      </div>

      <div className={styles.data}>
        <span className={styles.amount}>
          <b>{amount}</b>
        </span>

        <span className={styles.name}>{name}</span>
        <span className={styles.description}>{description}</span>
      </div>
    </div>
  )
}

export default OurResultItem
