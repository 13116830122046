//general
import React from "react"
//styles
import * as styles from "./styles.module.scss"

function AboutUsItem({ icon, text }) {
  return (
    <div className={styles.item}>
      <img src={icon} alt="" />

      <p className={styles.text}>{text}</p>
    </div>
  )
}

export default AboutUsItem
